import React, { useEffect, useContext, useState } from 'react'
import { Container, Flex, Box, Badge, Text, Heading, Select, Button } from 'theme-ui'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import styled from "styled-components"
import CardForm from '../CardForm/Block01'
import CreditCard from '../CreditCard/Block01'
import { getPricing } from '../../../../../../site/src/service/auth'
import { FormContext } from '@solid-ui-components/ContentForm'
import useForm from '@helpers/useForm'



const StyledApp = styled.main`
display: flex;
flex-direction: row;
flex-wrap: wrap;
padding: 1em;
justify-content: space-around;

& > div:not(:last-child) {
  margin-bottom: 2em;
}
`

const CreditDesignBlock01 = ({ content, ...props }) => {

  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const formId = "frmOdeme";


  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })
  }, [success, submitting, setFormValues])

  const handleChange = e => {
    const { name, value } = e.target;


    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [name]: value,

      }

    })

    setmsfliSati(!msfliSati);
  };


  const initialState = {
    name: "",
    number: "",
    expiryMonth: "",
    expiryYear: "",
    cvv: ""
  };




  const inputReducer = (state, action) => {

    return { ...state, [action.key]: action.value };
  };

  const [cardInfo, handleOnChange] = React.useReducer(
    inputReducer,
    initialState
  );


  const [dt, setDt] = useState(null);

  const [paketAdi, setPaketAdi] = useState("");
  const [tutar, setTutar] = useState("");
  const [msfliSati, setmsfliSati] = useState(false);




  useEffect(async () => {
    const gp = await getPricing();
    if (gp) {
      if (gp.h < 100) {
        setPaketAdi(gp.h + " Kredili Paket");
      } else
        setPaketAdi(gp.pa);
      setTutar(gp.t);
      setDt(gp);
    } else {
      setDt(null);
    }
  }, []);


  return (
    <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
      <Box >
        <CreditCard cardInfo={cardInfo} />
        {dt && <div style={{ margin: "20px" }}>
          Seçilen Paket:    <Text as="span" color="black">{paketAdi}  </Text>
          <Divider space="1" />
          Çekilecek Tutar:    <Text as="span" color="black" >{tutar}.00₺ (Kdv Dahil)  </Text>
          <Divider space="1" />
          <form name="myForm">
            <input type="checkbox" name={"myCheck"}
              checked={msfliSati}
              onChange={handleChange} />
            <a href='/mesafeli-satis-sozlesmesi' target="_blank" >Mesafeli Satış Sözleşmesini</a>
            <br />okudum onaylıyorum
          </form>
        </div>
        }
      </Box>
      <Box>
        <CardForm cardInfo={cardInfo} onChange={handleOnChange} mesafeli={msfliSati} />
      </Box>
    </Flex>
  )
};



export default WithDefaultContent(CreditDesignBlock01)

